import { z } from 'zod'
import { zfd } from 'zod-form-data'
import { ModelOrganization } from './organization'
import { ModelStatus, regexEmail, regexPhone, regexWeb } from './utils'

export enum UserGroup {
  superAdmin = 'superAdmin',
  organizationAdmin = 'organizationAdmin',
  companyAdmin = 'companyAdmin',
  appraiser = 'appraiser',
  member = 'member',
  // viewer = 'viewer',
}

export enum UserRole {
  managingBroker = 'managingBroker',
  coordinator = 'coordinator',
  broker = 'broker',
}

export type UserPhoto = {
  _id: string
  url: string
  thumbUrl: string
}

export type UserView = {
  _id: string
  name: string
  url: string
}

export type User = {
  _id: string
  tid: string
  bacId?: string
  created: string
  modified: string
  organization: ModelOrganization
  slug: string
  group: UserGroup
  role?: UserRole
  status: ModelStatus
  company?: {
    _id: string
    tid: string
    name: string
    slug: string
  }
  firstName: string
  middleName?: string
  lastName: string
  suffix?: string
  email: string
  phone?: string
  meta?: {
    memberSince?: string
    nrdsIdNumber?: string
    licenseNumber?: string
    licenseState?: string
    //licenseOriginDate?: string
    licenseActivationDate?: string
    licenseExpirationDate?: string
    //Insurance?: string
    insuranceExpirationDate?: string
    insuranceActivationDate?: string
    //Add these to the validator and the user form
    mobile?: string
    office?: string
    fax?: string
    website?: string
    linkedIn?: string
    twitter?: string
    facebook?: string
    title?: string
    memberships?: string
    propertyTypes?: string[]
    markets?: string
    description?: string
    photo?: UserPhoto
  }

  notifyNewContact?: boolean | string
  notifyDailyUpdate?: boolean | string
  notifyBroadcasts?: boolean | string
  notifyBroadcastLists?: string[]

  _password?: string
}

export type ModelUser = Pick<
  User,
  | '_id'
  | 'tid'
  | 'slug'
  | 'organization'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'group'
  | 'role'
  | 'company'
>

export type TokenUser = ModelUser & {
  originalLoginUser?: ModelUser
}

export const UserSchema = z
  .object({
    _id: z.string(),
    tid: z.string(),
    created: z.string(),
    modified: z.string(),
    organization: z.custom<ModelOrganization>(),
    slug: z.string(),
    group: z.nativeEnum(UserGroup),
    role: zfd.text(z.nativeEnum(UserRole).optional()),
    status: z.nativeEnum(ModelStatus),
    company: z
      .object({
        _id: z.string(),
        tid: z.string(),
        name: z.string(),
        slug: z.string(),
      })
      .optional(),
    firstName: z.string().min(1, 'First name is required'),
    // .min(4, 'Enter a name at least 4 characters'),
    middleName: z.string().optional(),
    lastName: z.string().min(1, 'Last name is required'),
    // .min(4, 'Enter a name at least 4 characters'),
    suffix: z.string().optional(),
    email: z.string().refine((val) => regexEmail(val), {
      message: 'Please enter a valid email',
    }),
    phone: z
      .string()
      .optional()
      .refine((val) => regexPhone(val), {
        message: 'Please enter a valid phone number.',
      }),
    meta: z
      .object({
        memberSince: z.string().optional(),
        nrdsIdNumber: z.string().optional(),
        licenseNumber: z.string().optional(),
        licenseState: z.string().optional(),
        licenseExpirationDate: z.string().optional(),
        licenseActivationDate: z.string().optional(),
        insuranceExpirationDate: z.string().optional(),
        insuranceActivationDate: z.string().optional(),
        mobile: z
          .string()
          .optional()
          .refine((val) => regexPhone(val), {
            message: 'Please enter a valid phone number.',
          }),
        office: z
          .string()
          .optional()
          .refine((val) => regexPhone(val), {
            message: 'Please enter a valid phone number.',
          }),
        fax: z
          .string()
          .optional()
          .refine((val) => regexPhone(val), {
            message: 'Please enter a valid phone number.',
          }),
        website: z
          .string()
          .optional()
          .refine((val) => regexWeb(val), {
            message:
              'Please enter a valid URL. Include http:// or https:// in the url.',
          }),
        linkedIn: z
          .string()
          .optional()
          .refine((val) => regexWeb(val), {
            message:
              'Please enter a valid URL. Include http:// or https:// in the url.',
          }),
        twitter: z
          .string()
          .optional()
          .refine((val) => regexWeb(val), {
            message:
              'Please enter a valid URL. Include http:// or https:// in the url.',
          }),
        facebook: z
          .string()
          .optional()
          .refine((val) => regexWeb(val), {
            message:
              'Please enter a valid URL. Include http:// or https:// in the url.',
          }),

        title: z.string().optional(),
        memberships: z.string().optional(),
        propertyTypes: zfd.repeatable(z.array(z.string())).optional(),
        markets: z.string().optional(),
        description: z.string().optional(),
        photo: z
          .object({
            _id: z.string(),
            url: z.string(),
            thumbUrl: z.string(),
          })
          .optional(),
      })
      .optional(),

    notifyNewContact: zfd.checkbox(),
    notifyDailyUpdate: zfd.checkbox(),
    notifyBroadcasts: zfd.checkbox(),
    notifyBroadcastLists: zfd.repeatable(z.array(z.string())).optional(),
  })
  .refine(
    (val) => {
      if (
        val.group !== UserGroup.superAdmin &&
        val.group !== UserGroup.organizationAdmin &&
        val.group !== UserGroup.appraiser
      ) {
        if (!val.company) {
          return false
        }
      }
      return true
    },
    {
      message: 'Company is required for this user group',
      path: ['company'],
    }
  ) satisfies z.ZodType<User>
